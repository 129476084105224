<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs6 sm4 md3 text-left>
        <span class="itemHeading"> Dashboard </span>
      </v-flex>
      <v-flex xs6 sm4 md3 lg3 xl3 pa-5>
        <v-select
          v-model="day"
          :items="days"
          item-text="days"
          item-value="days"
          outlined
          dense
          @change="dateFilter(day)"
          label="Date"
        ></v-select>
      </v-flex>

      <v-flex xs6 sm4 md3 pa-5>
        <v-menu
        v-if="day == 'Custom'"
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="checkDate1(), (menu1 = false)"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs6 sm4 md3 lg3 xl3 pa-5>
        <v-menu
        v-if="day == 'Custom'"
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="checkDate(), (menu2 = false)"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12>
        <Analytics v-bind:storage="dashboard" />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12>
        <GraphData v-bind:storage="dashboard" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Analytics from "./analytics";
import GraphData from "./graphData";

export default {
  components: {
    Analytics,
    GraphData,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
      day: "last 30 days",
      days: [
        "last 30 days",
        "last 3 months",
        "last 6 months",
        "last year",
        "Lifetime",
        "Custom",
      ],
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
    };
  },
  beforeMount() {
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 30);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
      this.getData();
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
  },
  methods: {
    dateFilter(day) {
      var to = new Date();
      var from = new Date(to.getTime());
       if (day == "last 30 days") {
        from = from.setDate(from.getDate() - 30);
        from = new Date(from);
      } else if (day == "last 3 months") {
        from = from.setMonth(from.getMonth() - 3);
        from = new Date(from);
      } else if (day == "last 6 months") {
        from = from.setMonth(from.getMonth() - 6);
        from = new Date(from);
      } else if (day == "last year") {
        from = from.setMonth(from.getMonth() - 12);
        from = new Date(from);
      }
      // else if (day == "Lifetime") {
      //   from = "";
      //   to = "";
      // }
      this.fromDate = from.toISOString().substr(0, 10);
      this.toDate = to.toISOString().substr(0, 10);
      this.getData();
    },
    checkDate() {
      // let ndate = new Date().toISOString().substr(0, 10);
      // if (this.fromDate == ndate) {

      var m = new Date(this.fromDate);
      // var j = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        const tomorrow = new Date(this.toDate);
        tomorrow.setDate(tomorrow.getDate() - 15);
        // this.fromDate = tomorrow.toISOString().substr(0, 10);
        // }
        // n.setDate(j.getDate() + 13);
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          m.setMonth(n.getMonth());
          m.setFullYear(n.getFullYear());
          m.setDate(n.getDate() - 15);
          // n.setMonth(m.getMonth());
          // n.setFullYear(m.getFullYear());
          // n.setDate(m.getDate() + 15);
          // console.log("fin", n);
          // console.log("Msss=", m.toISOString().substr(0, 10));
          // console.log("Nsss=", n.toISOString().substr(0, 10));
          // console.log("els case", m.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        n.setMonth(m.getMonth());
        n.setFullYear(m.getFullYear());
        n.setDate(m.getDate() + 15);
        this.toDate = n.toISOString().substr(0, 10);
        this.getData();
      }
    },
    checkDate1() {
      var m = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          n.setMonth(m.getMonth());
          n.setFullYear(m.getFullYear());
          n.setDate(m.getDate() + 7);
          // m.setMonth(n.getMonth());
          // m.setFullYear(n.getFullYear());
          // m.setDate(n.getDate() - 15);
          // console.log("fin", m);
          // console.log("els case", n.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        m.setMonth(n.getMonth());
        m.setFullYear(n.getFullYear());
        m.setDate(n.getDate() - 7);
        this.fromDate = m.toISOString().substr(0, 10);

        this.getData();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/admin",
        headers: {
          token: localStorage.getItem("token"),
        },
        params:{
          from: this.fromDate,
            to: this.toDate,
        }
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dashboard = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>